import React from 'react'
import PropTypes from 'prop-types'
import { Div, Container, Row, Col, Text } from 'atomize-jnh'
import './loader.css'
import { useIntl } from 'gatsby-plugin-intl'
import feesData from './data/feesData'

const FeesTable = props => {
  const intl = useIntl()
  function renderTableHead () {
    return (
      <Div border={{ b: '1px solid' }} borderColor="#eceff1" p={{ b: '15px' }}>
        <Container>
          <Row>
            <Col size={{ xs: '2', md: '3' }}>
              <Text tag="p" textColor="#37465a" textSize={{ xs: 'tiny', md: 'caption' }}>
                {intl.formatMessage({ id: 'fees.feeTable.market' })}
              </Text>
            </Col>

            <Col size={{ xs: '2', md: '3' }}>
              <Text tag="p" textColor="#37465a" textSize={{ xs: 'tiny', md: 'caption' }}>
                {intl.formatMessage({ id: 'fees.feeTable.limit' })}
              </Text>
            </Col>
            <Col size={{ xs: '4', md: '3' }}>
              <Text tag="p" textColor="#37465a" textSize={{ xs: 'tiny', md: 'caption' }}>
                {intl.formatMessage({ id: 'fees.feeTable.volume' })}
              </Text>
            </Col>
            <Col size={'3'}>
              <Text
                tag="p"
                textColor="#37465a"
                textAlign={{ md: 'center' }}
                textSize={{ xs: 'tiny', md: 'caption' }}>
                {intl.formatMessage({ id: 'fees.feeTable.level' })}
              </Text>
            </Col>
          </Row>
        </Container>
      </Div>
    )
  }
  function renderTableRow (props) {
    const { market, limit, range, levelName } = props

    return (
      <Div border={{ b: '1px solid' }} borderColor="#eceff1">
        <Container>
          <Row p={{ t: '10px', b: '10px' }}>
            <Col size={{ xs: '2', md: '3' }}>
              <Text
                tag="p"
                p={{ t: { xs: '0.5rem', md: '0.2rem' } }}
                w={{ xs: '155%', md: '100%' }}
                textWeight="100"
                textSize={{ xs: 'caption', md: 'body' }}>
                {market.toFixed(2)}%
              </Text>
            </Col>
            <Col size={{ xs: '2', md: '3' }}>
              <Text
                tag="p"
                p={{ t: { xs: '0.5rem', md: '0.2rem' } }}
                w={{ xs: '155%', md: '100%' }}
                textWeight="100"
                textSize={{ xs: 'caption', md: 'body' }}>
                {limit.toFixed(2)}%
              </Text>
            </Col>
            <Col size={{ xs: '4', md: '3' }}>
              <Text
                tag="p"
                p={{ t: { xs: '0.5rem', md: '0.2rem' } }}
                w={{ xs: '155%', md: '100%' }}
                textWeight="100"
                textSize={{ xs: 'caption', md: 'body' }}>
                {range}
              </Text>
            </Col>
            <Col size={'3'}>
              <Div>
                <Text
                  tag="p"
                  p={{ t: { xs: '0.5rem', md: '0.2rem' } }}
                  w={{ xs: '155%', md: '100%' }}
                  textWeight="100"
                  textSize={{ xs: 'caption', md: 'body' }}>
                  {levelName}
                </Text>
              </Div>
            </Col>
          </Row>
        </Container>
      </Div>
    )
  }

  function renderTableTitle () {
    return (
      <Div p={{ l: '1.5rem', t: '1.5rem' }}>
        <Text tag="h3"> {intl.formatMessage({ id: 'fees.feeTable.title' })}</Text>
        <br />
      </Div>
    )
  }

  return (
    <Div align="center" shadow="3" rounded="md" bg="white">
      {renderTableTitle()}
      {renderTableHead()}
      {feesData.map(data => {
        return renderTableRow({
          market: data.market,
          limit: data.limit,
          range: data.range,
          levelName: data.levelName
        })
      })}
    </Div>
  )
}

FeesTable.propTypes = {
  langCode: PropTypes.string,
  market: PropTypes.string,
  limit: PropTypes.string,
  range: PropTypes.string,
  levelName: PropTypes.string
}

export default FeesTable
