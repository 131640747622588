import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'gatsby-plugin-intl'
import { Div, Container, Row, Col, Text, Icon } from 'atomize-jnh'
import FeesTable from '../../Table/fee-table'
import WdTable from '../../Table/wd-table'
import { navigate } from 'gatsby'

const PageBody = ({ intl }) => {
  return (
    <Div p={{ b: '5rem', t: '1rem' }} style={{ width: '100%', background: '#e9f3fe' }}>
      <Container>
        <Div d="flex">
          <Icon
            onClick={() => navigate('/')}
            cursor="pointer"
            name="Home"
            color="#787878"
            size="20px"
          />
          <Text m={{ l: '5px' }} textColor="#787878">
            {'>'}
          </Text>
          <Text m={{ l: '5px' }} textColor="#787878">
            {intl.formatMessage({ id: 'fees.comissions' })}
          </Text>
        </Div>

        <Text textColor="#37465a" textWeight="600" textSize="subheader">
          {intl.formatMessage({ id: 'fees.comissions' })}
        </Text>
        <Row m={{ t: '36px' }}>
          <Col order={{ xs: '2', md: '1' }} size={{ xs: '12', md: '6' }}>
            <Div w={{ md: '90%' }} m={{ t: { xs: '36px', md: '0' } }}>
              <Div>
                <Text textColor="#4d68f0" textSize="title">
                  {intl.formatMessage({ id: 'fees.marketLimit' })}
                </Text>
                <Text
                  m={{ t: '15px' }}
                  textWeight="100"
                  textColor="#6b7c93"
                  textSize="body"
                  textAlign="justify">
                  {intl.formatMessage({ id: 'fees.marketDescription' })}
                </Text>
                <Text textWeight="100" textColor="#6b7c93" textSize="body" textAlign="justify">
                  {intl.formatMessage({ id: 'fees.limitDescription' })}
                </Text>
              </Div>
              <Div m={{ t: '36px' }}>
                <Text textColor="#4d68f0" textSize="title">
                  {intl.formatMessage({ id: 'fees.howProcessed' })}
                </Text>
                <Text
                  m={{ t: '15px' }}
                  textWeight="100"
                  textColor="#6b7c93"
                  textSize="body"
                  textAlign="justify">
                  {intl.formatMessage({ id: 'fees.howProcessedDescription' })}
                </Text>
              </Div>
              <Div m={{ t: '36px' }}>
                <Text textColor="#4d68f0" textSize="title">
                  {intl.formatMessage({ id: 'fees.howVolume' })}
                </Text>
                <Text
                  m={{ t: '15px' }}
                  textWeight="100"
                  textColor="#6b7c93"
                  textSize="body"
                  textAlign="justify">
                  {intl.formatMessage({ id: 'fees.howVolumeDescription' })}
                </Text>
              </Div>
              <Div m={{ t: '36px' }}>
                <Text m={{ t: '15px' }} textColor="#4d68f0" textSize="title">
                  {intl.formatMessage({ id: 'fees.depositWithdrawal' })}
                </Text>
                <Text textWeight="100" textColor="#6b7c93" textSize="body" textAlign="justify">
                  {intl.formatMessage({ id: 'fees.depositWithdrawalDescription' })}
                </Text>
              </Div>
            </Div>
          </Col>
          <Col order={{ xs: '1', md: '2' }} size={{ xs: '12', md: '6' }}>
            <Div>
              <FeesTable />
            </Div>
            <Div m={{ t: '36px' }}>
              <WdTable />
            </Div>
          </Col>
        </Row>
      </Container>
    </Div>
  )
}

PageBody.propTypes = {
  intl: {
    formatMessage: PropTypes.func
  }
}

export default injectIntl(PageBody)
