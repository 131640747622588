import React from 'react'
import PropTypes from 'prop-types'
import CoinCircle from '../CoinCircle'
import { Div, Container, Row, Col, Text } from 'atomize-jnh'
import './loader.css'
import { useIntl } from 'gatsby-plugin-intl'
const feesData = {
  es: [
    {
      code: 'BTC',
      name: 'Bitcoin',
      deposits: '฿ 0,00 BTC',
      withdrawals: '฿ 0,0003 BTC'
    },
    {
      code: 'ETH',
      name: 'Ether',
      deposits: 'Ξ0,00 ETH',
      withdrawals: 'Ξ0,005 ETH'
    },
    {
      code: 'XRP',
      name: 'XRP',
      deposits: '0,00 XRP',
      withdrawals: '0,00 XRP'
    },
    {
      code: 'XLM',
      name: 'Stellar Lumens',
      deposits: '0,00 XLM',
      withdrawals: '0,00 XLM'
    },
    {
      code: 'TRX',
      name: 'Tron',
      deposits: '0,00 TRX',
      withdrawals: '0,00 TRX'
    },
    {
      code: 'BNB',
      name: 'Binance Coin',
      deposits: '0,00 BNB',
      withdrawals: '0,01 BNB'
    },
    {
      code: 'DAI',
      name: 'DAI',
      deposits: '0,00 DAI',
      withdrawals: '25 DAI'
    },
    {
      code: 'LTC',
      name: 'Litecoin',
      deposits: 'Ł0,00 LTC',
      withdrawals: 'Ł0,003 LTC'
    },
    {
      code: 'CHA',
      name: 'Chaucha',
      deposits: '0,00 CHA',
      withdrawals: '1,00 CHA'
    },
    {
      code: 'LUK',
      name: 'Luka',
      deposits: '0,00 LUK',
      withdrawals: '1,00 LUK'
    },
    {
      code: 'BCH',
      name: 'Bitcoin Cash',
      deposits: '฿ 0,00 BCH',
      withdrawals: '฿ 0,001 BCH'
    },
    {
      code: 'DASH',
      name: 'Dash',
      deposits: '0,00 DASH',
      withdrawals: '0,002 DASH'
    },
    {
      code: 'USDT',
      name: 'Tether USD',
      deposits: '0,00 USDT',
      withdrawals: '25 USDT'
    },
    {
      code: 'EOS',
      name: 'EOS',
      deposits: '0,00 EOS',
      withdrawals: '0,1 EOS'
    },
    {
      code: 'DOT',
      name: 'DOT',
      deposits: '0,00 DOT',
      withdrawals: '0,1 DOT'
    }
  ]
}

const FeesTable = props => {
  const intl = useIntl()
  function renderTableHead () {
    return (
      <Div border={{ b: '1px solid' }} borderColor="#eceff1" p={{ b: '15px' }}>
        <Container>
          <Row>
            <Col size="4">
              <Text tag="p" textColor="#37465a" textSize={{ xs: 'caption', md: 'caption' }}>
                {intl.formatMessage({ id: 'fees.WdTable.currency' })}
              </Text>
            </Col>
            <Col size="4">
              <Text tag="p" textColor="#37465a" textSize={{ xs: 'caption', md: 'caption' }}>
                {intl.formatMessage({ id: 'fees.WdTable.deposit' })}
              </Text>
            </Col>
            <Col size={'4'}>
              <Text tag="p" textColor="#37465a" textSize={{ xs: 'caption', md: 'caption' }}>
                {intl.formatMessage({ id: 'fees.WdTable.withdrawal' })}
              </Text>
            </Col>
          </Row>
        </Container>
      </Div>
    )
  }
  function renderTableRow (props) {
    const { name, deposits, withdrawals, currencyCode } = props

    return (
      <Div border={{ b: '1px solid' }} borderColor="#eceff1">
        <Container>
          <Row p={{ t: '10px', b: '10px' }}>
            <Col size="4" d="flex">
              <Div d={{ xs: 'none', md: 'flex' }}>
                <CoinCircle size={30} currencyCode={currencyCode} />
              </Div>
              <Text
                tag="p"
                p={{ t: { xs: '0.5rem', md: '0.2rem' }, l: '5px' }}
                w={{ xs: '155%', md: '80%' }}
                textWeight="100"
                textSize={{ xs: 'caption', md: 'body' }}>
                {name}
              </Text>
            </Col>
            <Col size="4">
              <Text
                tag="p"
                p={{ t: { xs: '0.5rem', md: '0.2rem' } }}
                w={{ xs: '155%', md: '100%' }}
                textWeight="100"
                textSize={{ xs: 'caption', md: 'body' }}>
                {deposits}
              </Text>
            </Col>
            <Col size={'4'}>
              <Text
                tag="p"
                p={{ t: { xs: '0.5rem', md: '0.2rem' } }}
                w={{ xs: '155%', md: '100%' }}
                textWeight="100"
                textSize={{ xs: 'caption', md: 'body' }}>
                {withdrawals}
              </Text>
            </Col>
          </Row>
        </Container>
      </Div>
    )
  }

  function renderTableTitle () {
    return (
      <Div p={{ l: '1.5rem', t: '1.5rem' }}>
        <Text tag="h3">{intl.formatMessage({ id: 'fees.WdTable.title' })}</Text>
        <br />
      </Div>
    )
  }

  return (
    <Div align="center" shadow="3" rounded="md" bg="white">
      {renderTableTitle()}
      {renderTableHead()}
      {feesData.es.map(data =>
        renderTableRow({
          currencyCode: data.code,
          name: data.name,
          deposits: data.deposits,
          withdrawals: data.withdrawals
        })
      )}
    </Div>
  )
}

FeesTable.propTypes = {
  name: PropTypes.string,
  deposits: PropTypes.string,
  withdrawals: PropTypes.string,
  langCode: PropTypes.string,
  currencyCode: PropTypes.string
}

export default FeesTable
