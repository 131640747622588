const feesData = [
  {
    market: 0.66,
    limit: 0.4,
    range: '<1.000 USD',
    levelName: '🦐 Camarón'
  },
  {
    market: 0.56,
    limit: 0.35,
    range: '<10.000 USD',
    levelName: '🦀 Cangrejo'
  },
  {
    market: 0.46,
    limit: 0.3,
    range: '<50.000 USD',
    levelName: '🐙 Pulpo'
  },
  {
    market: 0.36,
    limit: 0.25,
    range: '<100.000 USD',
    levelName: '🐬 Delfín'
  },
  {
    market: 0.26,
    limit: 0.15,
    range: '<300.000 USD',
    levelName: '🦈 Tiburón'
  },
  {
    market: 0.2,
    limit: 0.1,
    range: '>300.000 USD',
    levelName: '🐋 Ballena'
  }
]

export default feesData
